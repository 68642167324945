// Here you can add other styles
.form-with-muiPicker {
    .MuiFormControl-root {
        border: 1px solid #9da5b1;
    }

    .MuiInput-underline:before {
        border-bottom: 0;
    }

    .MuiInput-underline:after {
        border-bottom: 0;
    }

    .MuiInput-underline:hover:not(.Mui-disabled)::before {
        border-bottom: 0;
    }

}

.mat-select {
    .MuiInputBase-input {
        padding: 3px 0;
    }

    .MuiSelect-select:focus {
        background-color: #fff !important;
    }
}

.mat-date-pciker {
    .MuiInputBase-formControl {
        padding: 3px 0;

        .MuiInputBase-input {
            padding-left: 5px;
        }
    }
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.38);
}

.mat-autocomplete {
    padding: 0;

    .MuiFormControl-root {
        border: 0;

        .MuiAutocomplete-inputRoot {
            padding: 0px;

            fieldset {
                border: 0;
            }
        }
    }
}

.cursor-style {
    cursor: pointer !important;
}

.MuiDialog-root {
    z-index: 99999999 !important;
}

.MuiPopover-root {
    z-index: 99999999 !important;
}

.print-sticker {
    font-size: 16px;

    .boldtext {
        font-weight: 500;
    }
}

.printRowHeight {
    height: 90px !important;
}

.creditNotePrint {
    thead {
        font-weight: 600;
        font-size: 14rem;

        .second-header {
            font-size: 12px;
        }
    }

    tbody {
        &.protocol-body {
            .protocol-head {
                font-weight: bold;

                .first {
                    width: 10%
                }

                .second {
                    width: 10%
                }

                .third {
                    width: 40%
                }

                .fourth {
                    width: 40%
                }
            }

            .subtitle {
                font-weight: 600;
            }
        }

        td {
            white-space: pre-line;
            padding: 0.15rem 0.2rem;

            .physio-assessment-title {
                font-size: 16px;
                text-decoration: underline;
                font-weight: 700;
            }

            .physio-assessment-subtitle {
                font-size: 13px;
                text-decoration: underline;
                font-weight: 600;
            }
        }

        font-size: 11px;
        font-weight: 400;

        .font-weight {
            font-weight: 500;
        }

        .blank-height {
            height: 60px;
        }

        .sub-table-font {
            font-size: 11px;
        }

        .stamp-box {
            height: 40px;
            width: 54px;
            border: 1px dashed #111 !important;
            // border-style: dashed;
            margin-right: 15px;
        }

        .no-border {
            margin-top: 5px;
            border-width: 0px 0;
        }
    }
}

.receipt-print {
    margin: 5.5rem 1.5rem 1.5rem;

    thead {
        font-weight: 600;
        font-size: 12rem;

        .second-header {
            font-size: 10px;
        }
    }

    tbody {
        td {
            white-space: pre-line;
            padding: 0.15rem 0.2rem;
        }

        font-size: 9px;
        font-weight: 500;

        .font-weight {
            font-weight: 600;
        }

        .blank-height {
            height: 60px;
        }

        .sub-table-font {
            font-size: 11px;
        }

        .stamp-box {
            height: 40px;
            width: 54px;
            border: 1px dashed #111 !important;
            // border-style: dashed;
            margin-right: 15px;
        }

        .no-border {
            margin-top: 5px;
            border-width: 0px 0;
        }
    }
}

textarea {
    white-space: pre-line;
}

.dynamic-modal {
    tbody {
        td {
            white-space: pre-line;
        }
    }
}

html:not([dir=rtl]) .sidebar-nav {
    .nav-group-items {
        .nav-link {
            padding-left: 2.5rem !important;
        }
    }
}